#menu-icon {
  display: none !important;
}

nav .app-bar {
  position: fixed;
}

.skills-div {
  padding: 5px;
}

.skills-div li {
  color: white;
}

.download-button {
  margin-top: 20px;
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  border: 1px solid grey;
}

.download-button a {
  color: white;
  text-decoration: none;
}

.download-button:hover {
  background-color: grey;
}

.skills-div {
  padding: 10px 20px;
  background-color: black;
  border-radius: 8px;
}

.skills-list {
  list-style-type: none;
  padding-left: 8px;
  margin: 0;
  font-size: 1.1em;
}

.skills-list li {
  color: white;
  padding: 5px 0;
  border-bottom: 1px solid #bdc3c7;
  transition: color 0.3s ease;
}

.skills-list li:last-child {
  border-bottom: none;
}

.skills-list li:hover {
  color: #1abc9c;
}

.linkedin-link {
  margin-top: 10px;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  display: flex;
  display: inline;
}

button {
  margin-right: 0 !important;
}

.linkedin-link a {
  color: white;
  text-decoration: none;
  /* margin-left: 5px; */
}

#projects-link:hover {
  background-color: rgb(45, 42, 42);
}

@media (max-width: 767px) {
  #nav-toolbar {
      width: 90% !important;
  }
  #menu-icon {
    display: block !important;
  }
  .mobile-buttons {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mobile-buttons {
    display: none;
  }
  #nav-toolbar {
      width: 90% !important;
  }
  #menu-icon {
    display: block !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  #nav-toolbar {
      width: 95% !important;
  }
}
.home-div {
  margin: 0 auto;
  width: 90%;
}
.home-content {
  margin-top: 6%;
}

.about {
  width: 70%;
  margin: 0 auto;
}

.about, .website, .certificates {
  margin-bottom: 40px;
}

.certificates {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: center;
  line-height: 1.4;
}

.certificate-item {
  display: flex;
  align-items: center;
  gap: 50px; /* Space between image and text */
  width: 70%; /* Fixed width for the project item */
  max-width: 100%; /* Ensure it doesn't overflow the container */
  margin-bottom: 70px;
  font-size: 17px;
}

.certificate-item img {
  width: 300px; /* Fixed width for the image */
  height: 250px; /* Maintain aspect ratio */
  display: block;
}

.certificate-item p {
  margin: 0;
  word-wrap: break-word;
  flex: 1;
}

.certificates-mobile {
  display: none;
  line-height: 1.4;
}

.certificate-item-mobile {
  text-align: center;
}

.certificate-item-mobile img {
  width: 100%;
  height: 250px;
  margin-bottom: 20px;
}

.certificate-item-mobile p {
  margin: 0 0 10px 0; /* Adds spacing between paragraph and list */
  text-align: left;
  margin: 0 auto;
}

.certificate-item-mobile ul {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left; 
  color: white;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .home-div {
    width: 85%;
  }
  .home-content {
    margin-top: 22%;
  }
  .about {
    width: 100%;
  }
  .certificates {
    display: none;
  }
  .certificates-mobile {
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .home-content {
    margin-top: 15%;
  }
  .about {
    width: 85% !important;
  }
  .certificate-item {
    width: 100% !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .home-content {
    width: 95%;
    margin-top: 12%;
  }
  .about {
    width: 80% !important;
  }
  .about p {
    font-size: 20px !important;
  }
  .certificate-item {
    width: 90% !important;
  }
}
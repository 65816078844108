.contact-div {
    background-color: black;
    min-height: 100vh;
    padding-top: 20px;
    color: white;
    padding-bottom: 20px;
}

.contact-message-block {
    position: relative;
    margin: 0 auto;
    max-width: 500px;
    padding: 10px;
    margin-top: 8%;
}

.message-text-boxes {
    height: auto; /* Allow height to adjust dynamically */
    width: 90%; /* Use a percentage for responsiveness */
    max-width: 400px; /* Restrict the maximum width */
    margin: 0 auto; /* Center the box */
}

.text-div {
    margin: 20px 0 20px;
}

.text-div label {
    color: grey;
    margin-bottom: 10px;
}

.text-div input {
    height: 45px;
}

.text-div input, textarea {
    border-radius: 3px;
    border: 0;
    padding: 7px;
    width: 100%; /* Take up full width of the parent */
    box-sizing: border-box; /* Include padding and border in the width */
    font-size: 15px;
}

.text-div input::placeholder,
.text-div textarea::placeholder {
    color: rgb(171, 165, 165);
}

.contact-div button {
    border: 1px solid grey;
    padding: 8px 20px 8px;
    color: white;
    background-color: black;
    border-radius: 3px;
    cursor: pointer;
}

.contact-div button:hover {
    background-color: grey;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .contact-message-block {
        margin-top: 20%;
    }
}

@media (max-width: 767px) {
    .contact-message-block {
        width: 90%;
        margin-top: 15%;
    }
    .message-text-boxes {
        width: 100%;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1366px) {
    .contact-message-block {
        margin-top: 18%;
    }
}